

//polyfill
export const CustomEvent = function  ( event, params ) {
  params = params || { bubbles: false, cancelable: false, detail: undefined };
  var evt = document.createEvent( 'CustomEvent' );
  evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
  return evt;
};

if (!typeof window.CustomEvent === "function" ) {
  CustomEvent.prototype = window.Event.prototype;
  window.CustomEvent = CustomEvent;
}

export const TriggerEvent = function(eventId, data, scope) {

  var event;

  if (eventId != "pageScroll" && location.hostname == "localhost") {
    //debug
    // console.log(eventId+" triggered" + (data ? ", event data: "+JSON.stringify(data) : ""));
  }

  if (!scope) scope = document;
  scope.dispatchEvent(new CustomEvent(eventId, {detail: data}));

};
