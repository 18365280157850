import { TriggerEvent } from "./utils/triggerevent"
import { Project } from "./post";

var Main = {
  init: function (e) {
    if (this.initialized) return false;
    this.initialized = true;

    //scroll event
    var main = document.querySelector(".main");
    var scrollTop = main.scrollTop;
    main.addEventListener("scroll", function () {
      var st = main.scrollTop;
      var scrollDir = scrollTop < st ? 1 : scrollTop > st ? -1 : 0;
      if (scrollTop < 0) scrollDir = 0;
      var delta = Math.abs(scrollTop - st);
      scrollTop = st;
      TriggerEvent("pageScroll", {
        scrollTop: st,
        scrollDirection: scrollDir,
        scrollDelta: delta,
      });
    });

    //init window resize
    window.addEventListener("resize", function () {
      var w = window.innerWidth;
      var h = window.innerHeight;
      var displayMode;
      if (w < 768) {
        displayMode = "mobile";
        document.querySelector("body").classList.add("device-mobile");
      } else {
        displayMode = "desktop";
        document.querySelector("body").classList.remove("device-mobile");
      }
      TriggerEvent("pageResize", {
        width: w,
        height: h,
        displayMode: displayMode,
      });
    });
    TriggerEvent("resize", {}, window);

    //adding backdropfilter where available
    if ("webkitBackdropFilter" in document.documentElement.style) {
      document.querySelector("body").classList.add("enable-backdropFilter");
    }

    //history back cache detection
    window.addEventListener("pageshow", function (e) {
      if (e.persisted) {
        document.querySelector("#Loader").classList.remove("show");
      }
    });

    //init page anchors
    var anchors = document.querySelectorAll("a:not([href^='#'])");
    Array.from(
      anchors,
      function (anchor, i) {
        anchor.addEventListener("click", function (e) {
          e.preventDefault();
          document.querySelector("#Loader").classList.add("show");
          setTimeout(
            function (href) {
              location.href = href;
            },
            800,
            this.href
          );
        });
      }.bind(this)
    );

    //hide loader
    document.querySelector("#Loader").classList.remove("show");

    //trigger ready event for modules
    TriggerEvent("ready");

    // init subpages
    if (document.querySelector('body.project')) {
      Project.init()
    }
  },
};

if (document.readyState != "loading") {
  Main.init();
} else {
  document.addEventListener("DOMContentLoaded", Main.init);
}
