export const Project = {
  lazyLoad: function () {
    var lazyImages = document.querySelectorAll("img.lazy");

    if ("IntersectionObserver" in window) {
      let lazyImageObserver = new IntersectionObserver(function (
        entries,
        observer
      ) {
        entries.forEach(function (entry) {
          if (entry.isIntersecting) {
            let lazyImage = entry.target;
            lazyImage.addEventListener("load", function () {
              this.classList.add("loaded");
            });
            lazyImage.src = lazyImage.dataset.src;
            if (lazyImage.dataset.srcset)
              lazyImage.srcset = lazyImage.dataset.srcset;
            lazyImage.classList.remove("lazy");
            lazyImageObserver.unobserve(lazyImage);
          }
        });
      });

      lazyImages.forEach(function (lazyImage) {
        lazyImageObserver.observe(lazyImage);
      });
    } else {
      // Possibly fall back to event handlers here
    }
  },

  init: function () {
    Project.lazyLoad();
    if (document.querySelector("body.project")) {
      document.addEventListener("pageScroll", function (e) {
        var st = e.detail.scrollTop;
        var floatingTitle = document.querySelector(".floating-title");
        var footer = document.querySelector("#Footer");

        var footerRect = footer.getBoundingClientRect();
        if (st > 240 && footerRect.y - window.innerHeight > 0) {
          floatingTitle.classList.add("show");
        } else {
          floatingTitle.classList.remove("show");
        }
      });
    }
  },
};
